import { DimensionCategory } from './types';

export const DimensionColorsAndIcons: Record<
  string,
  {
    color: string;
    icon_url: string;
    no_circle_icon_url: string;
  }
> = {
  'Reputation Management': {
    color: '#F1B434',
    icon_url: '/icons/ReputationManagement.svg',
    no_circle_icon_url: '/icons/ReputationManagementNoCircle.svg',
  },
  'Self-Management': {
    color: '#C73663',
    icon_url: '/icons/SelfManagement.svg',
    no_circle_icon_url: '/icons/SelfManagementNoCircle.svg',
  },
  'Relationships + Teamwork': {
    color: '#632F6B',
    icon_url: '/icons/RelationshipsAndTeamwork.svg',
    no_circle_icon_url: '/icons/RelationshipsAndTeamworkNoCircle.svg',
  },
  'Business Orientation': {
    color: '#0077B6',
    icon_url: '/icons/BusinessOrientation.svg',
    no_circle_icon_url: '/icons/BusinessOrientationNoCircle.svg',
  },
  Execution: {
    color: '#64B0BB',
    icon_url: '/icons/Execution.svg',
    no_circle_icon_url: '/icons/ExecutionNoCircle.svg',
  },
  'Pursuit of Excellence': {
    color: '#33937C',
    icon_url: '/icons/PursuitOfExcellence.svg',
    no_circle_icon_url: '/icons/PursuitOfExcellenceNoCircle.svg',
  },
  'Dynamic Approach': {
    color: '#E87722',
    icon_url: '/icons/DynamicApproach.svg',
    no_circle_icon_url: '/icons/DynamicApproachNoCircle.svg',
  },
} as const;
/**
 * Do not re-order these per MC -- we render this array in order on
 *
 * - Accelerate reports page 5 Modern Lawyer Competency Framework
 *   https://www.figma.com/design/mi5pPTBpB6vAj03s179pEi?node-id=38-2641#951041105
 *
 * - Accelerate reports page 6 Dimension Behaviors
 *   https://www.figma.com/design/mi5pPTBpB6vAj03s179pEi?node-id=33-289#951041337
 *
 */
export const DimensionCategories: DimensionCategory[] = [
  {
    name: 'Approaching Work',
    dimensions: [
      {
        name: 'Reputation Management',
        icon_url: '/icons/ReputationManagement.svg',
        icon_no_circle_url: '/icons/ReputationManagementNoCircle.svg',
        hex_color: '#F1B434',
        definition:
          'How you value being seen as credible, direct, and reliable and the steps you take to be seen in this way.',
        competencies: [
          {
            name: 'Accountable',
            definition:
              'An accountable lawyer inspires confidence and earns trust. They get the job done and ensure projects are completed in the expected time frame. They show ownership over their work and are dependable. In addition to holding themselves accountable, they hold others accountable. An accountable lawyer recognizes that others cannot complete their tasks effectively unless they do the same.',
            for_reflection: [
              'To what extent do you honor all your commitments? Do you see commitments as flexible and negotiable? How much does your attitude alter depending on how important the commitment is to you personally or how much you care about it? How does your attitude about commitments impact other involved?',
              'What do you do when you know you are going to miss a deadline? What steps do you take to avoid that happening in the first place?',
              "How do you ensure that members of your team do what is expected of them? What do you do when they don't?",
              'When managing others, how clear and explicit are you as to your expectations: what needs to be done, by when, what the deliverable(s) is and who is responsible for what? What can you do to ensure that they understand what you (and others) expect of them? How do you follow up to check on progress and see what guidance or resources they need? How else do you help them meet their deadlines?',
            ],
          },
          {
            name: 'Credible',
            definition:
              'A credible lawyer is candid, direct, and is willing to admit when they make a mistake. They provide others with appropriate credit for their work and follow rules and guidelines. A lawyer with high credibility treats people with respect and understands that their credibility is essential for building and maintaining trust.',
            for_reflection: [
              'How direct and candid are you in your communication? How comfortable are you sharing your perspectives and feedback with others?',
              'When deciding on a course of action, how do you consider the possible impact it could have on others (your team, your clients, your firm)?',
              "Recognizing that transparency can build others' confidence in you, how do you respond when you make a mistake? Is your default to own up to it and work on a solution, or do you try to figure it out on your own? How soon do you communicate the issue to those affected? Do you seek advice and support, or do you prefer to handle it on your own?",
              'Have you ever undertaken a values exercise? How clear are you about your values and how they align with your role?',
              "While it's important to ensure that others are aware of your contributions, what do you do to share credit for successes and call out the contributions of others? ",
            ],
          },
          {
            name: 'Reputation Focused',
            definition:
              'A lawyer who is reputation-focused is aware of how they are viewed and is careful to present an exterior that enhances and builds upon their reputation. They project confidence and make sure their accomplishments are seen by others.',
            for_reflection: [
              'How clear are you about your professional brand and how others see you? How do different kinds of people you know describe you? What would you want them to say about you? Consider the accomplishments and results you can share with others to help them understand what you do, how you do it, and what it is like to work with you. How else can you ensure that others are aware of the value you bring to your clients and your work?',
              'When do you feel most confident? In what scenarios? When do you feel less confident? What type of situations do you tend to avoid? What would help you feel more confident in those situations?',
              'How do you prepare for meetings with clients (or more senior colleagues)? What else could you do to ensure that you create a positive impression and give people a reason to remember you and what you do? ',
            ],
          },
        ],
        typical_behaviors: [
          'Adheres to deadlines and follows rules',
          'Tells the truth even when not flattering',
          'Holds themselves and others accountable',
          'Cultivates an internal brand',
        ],
      },
      {
        name: 'Self Management',
        icon_url: '/icons/SelfManagement.svg',
        icon_no_circle_url: '/icons/SelfManagementNoCircle.svg',
        hex_color: '#C73663',
        definition:
          'How you see yourself in the workplace, prioritize obligations, and engage in learning.',
        competencies: [
          {
            name: 'Grounded',
            definition:
              'A grounded lawyer has a clear sense of self. They know what is and is not within their control, and do not take things personally. They set strong boundaries.',
            for_reflection: [
              'Recognizing that you are in a client-focused professional services environment, what, if any, non-negotiable commitments do you have? Where is there flexibility in your environment or with specific clients and colleagues?',
              'Reflect on what role you play and could play in specific outcomes. Focus on your ability to get things done as opposed to outside circumstances that might lead to obstacles.',
              'How clear are you with yourself and others about setting boundaries? Think about a time when you had to express a preference regarding how you like to work. How did you communicate your preferences? How explicit were you? How effective was it? What would you do differently next time around?',
              'Has anyone disregarded or overruled a preference you have expressed? How did you respond? Did you choose to push back? If so, how did you do it? What was the outcome?',
            ],
          },
          {
            name: 'Growth Oriented',
            definition:
              'A growth-oriented lawyer actively looks for and embraces opportunities to advance their professional and personal development. They engage with feedback. When relevant developmental feedback is given, they change their behavior accordingly.',
            for_reflection: [
              'What opportunities are there for you to seek feedback and advice? How can you make it easier for others to provide feedback? Maybe after every significant project or assignment? Memorialize it and reflect on it when approaching a new project. Take note of what you incorporated and how it impacted your work.',
              'As you become more senior, the less feedback you typically receive. Even experienced lawyers benefit from feedback, but what is needed evolves in the same way that our experience and expertise develop. What kind of feedback do you most want? What is most important for you to understand about your performance? How can you focus on getting that feedback?',
              'Even if you receive feedback that you consider to be critical or unfair, what can you take from it that might help you? Once you have processed your initial reaction, is there anything that resonates?',
              'What development opportunities are available to you? What would help you take your knowledge and expertise to the next level? Where do you see opportunities to incorporate new or enhanced knowledge and skills in your day-to-day?',
            ],
          },
          {
            name: 'Organized',
            definition:
              'An organized lawyer is skilled in independently managing their time and commitments. They handle competing deadlines and prioritize and re-prioritize their work appropriately.',
            for_reflection: [
              'How do you stay on track? Do you use a to-do list, a list app, spreadsheets, project management tools, or calendar invites/time blocking? If time management is an area for improvement, how can you integrate tools into your routine? Keep in mind that it can take anywhere from a few weeks to several months of consistent effort in the same context to develop new habits.',
              'How do you evaluate the immediate goals and challenges facing your team or clients? If you are not familiar with it, the Eisenhower Matrix may be a helpful tool. When you consider what is urgent versus what is important, how does this affect your perspective on how you are using your time and energy? If your time is taken up with activities that are both urgent and important, how can you ensure that you also allow time for activities that are important but do not require your immediate attention?',
              'What strategies have you found effective in helping you organize your schedule and your commitments? What has and has not worked? What would you like to try again? What are you open to changing about how you organize your time? Who or what could help you stay on track?',
            ],
          },
        ],
        typical_behaviors: [
          'Knows own limits and sets boundaries around skills, timing, and personal preferences',
          'Prioritizes growth',
          'Manages priorities',
        ],
      },
    ],
  },
  {
    name: 'Making Connections',
    dimensions: [
      {
        name: 'Relationships + Teamwork',
        icon_url: '/icons/RelationshipsAndTeamwork.svg',
        icon_no_circle_url: '/icons/RelationshipsAndTeamworkNoCircle.svg',
        hex_color: '#642F6C',
        definition:
          'How you develop relationships in the workplace and leverage them to advance.',
        competencies: [
          {
            name: 'Emotionally Intelligent',
            definition:
              'An emotionally intelligent lawyer is effective at understanding and regulating their own emotional responses. They are strong in interpreting and anticipating the emotions of others. An emotionally intelligent lawyer is able to effectively recognize and take account of others’ feelings.',
            for_reflection: [
              'What kinds of situations trigger a strong emotional reaction in you? How well do you manage your own emotions?',
              'What have you found successful in managing your emotions when under stress? What difference would it make to you if you were to pause before responding or step away to collect your thoughts?',
              "How much do you pay attention to non-verbal communication in your colleagues' body language, tone, and facial expressions? What unspoken cues can you use to provide additional context about what they are feeling or might need? ",
              'Emotional intelligence has multiple facets. Which of your emotions can you leverage to address different scenarios and maintain good relationships across the board?  For example, empathy may help you form more meaningful connections with colleagues.',
              "What else can you do to understand your teams' thoughts and perspectives?",
            ],
          },
          {
            name: 'Influential',
            definition:
              'An influential lawyer impacts the decisions and actions of others, with or without formal authority. They can construct compelling arguments and are effective at leveraging third parties to influence others. An influential lawyer will be a strong leader, guiding, inspiring, and empowering colleagues and clients.',
            for_reflection: [
              'By building specialized expertise in a particular area, others will see you as an authority. If you have not already done so, what can you do to develop and enhance your expertise and influence in the conversation?',
              'How do you create and maintain trust? Where do you have opportunities to be authentic and genuine?',
              'How do you keep others engaged in initiatives that are important to you?',
              'When constructing an argument, how do you determine what is important to your target audience and use that to inform the points you make?',
              'What do you find most effective when getting people to see your point? What else could you do?',
            ],
          },
          {
            name: 'Socially Adept',
            definition:
              'A socially adept lawyer is well-networked and understands how to leverage relationships to get work done. They determine who knows what within the firm and strategically develop relationships to gain access to support, expertise, and experience. They will proactively cultivate mentoring relationships.',
            for_reflection: [
              'Who do you socialize with most frequently at work? What role do they play in the professional brand you have cultivated and continue to develop?',
              'If you do not have a network of people to lean on for advice, connections, assistance, and feedback, what can you do to create one? Who would you include? How can you incorporate diverse perspectives to avoid reinforcing a single viewpoint on how to do things? If you anticipate challenges in building your network, what resources or support would help you?',
              'How well do you understand who the experts are in your organization? If you do not have good relationships with those in the know, what steps can you take to create and build those connections?',
            ],
          },
          {
            name: 'Socially Attentive',
            definition:
              'A socially attentive lawyer effectively forms and maintains relationships with others. They prioritize socializing with others in a way that is authentic to them. They make an effort to get to know people both inside and outside their immediate work sphere, effectively forming relationships with a broad range of individuals. They value collaboration over working entirely independently.',
            for_reflection: [
              'How much do you interact with people outside of your immediate group or at different levels of seniority? What is the nature of those interactions? When you think about your professional network(s), what steps can you take to increase the number and diversity of people you know?',
              'How can you expand and strengthen your relationships with colleagues beyond official interactions, including out-of-office activities? What opportunities can you create for informal and unstructured interactions that enable you to get to know one another as individuals?',
              'If you tend to work on assignments and matters where your work is discrete and does not require you to work in a team, what opportunities exist for you to work on matters where you can be part of a larger team or collaborate with others?',
            ],
          },
        ],
        typical_behaviors: [
          'Prioritizes building and leveraging relationships',
          'Responds to others’ needs',
          'Controls own emotions; influences others',
        ],
      },
    ],
  },
  {
    name: 'Delivering Results',
    dimensions: [
      {
        name: 'Business Orientation',
        icon_url: '/icons/BusinessOrientation.svg',
        icon_no_circle_url: '/icons/BusinessOrientationNoCircle.svg',
        hex_color: '#0077B6',
        definition:
          'How you understand the business of law, and how you meet client needs.',
        competencies: [
          {
            name: 'Client Focused',
            definition:
              'A client-focused lawyer incorporates the needs and perspectives of clients, both internal and external, in everything they do. They anticipate and meet those needs. A client-focused lawyer prioritizes being responsive and available to clients.',
            for_reflection: [
              'What can you do to ensure you understand as much as possible about your clients’ businesses and how matters fit within their broader goals? What steps do you take to understand your clients’ priorities and needs in a particular matter? How can you go above and beyond what is asked?',
              'What opportunities are there to debrief with your clients after every matter you complete? If the firm does not have a formal process, how can you find time to ask your clients about how things worked out, what worked well, and what could be improved? Consider breaking the matter into discrete stages and components, allowing for specific input on each key element.',
              "If you have concerns about a client's proposed course of action, what can you do to communicate this clearly, without hesitation, and in an objective way? If you have strong reasoning, how can you present it in a compelling way while being tactful and client-focused? If you do not feel comfortable going directly to the client, bring it to the attention of a more senior and more experienced colleague.",
            ],
          },
          {
            name: 'Strategic',
            definition:
              'A strategic lawyer understands the law firm model from a business perspective and their role in that model. They take a long-term perspective with their career, are big-picture thinkers, and are proficient in identifying clients’ priorities and patterns in the market.',
            for_reflection: [
              'To what extent do you stay future-focused? How much time do you devote to identifying your goals and where you want to be, both long-term and short-term?',
              'Consider using the GROW model (Goals, Realities, Options, Will/Way Forward) to create a plan that recognizes where you are now, notes what is in the way of you achieving your goals, identifies options for action to accomplish your goals and your chosen way forward.',
              "What sources can provide relevant market and competitive intelligence, insights into your market, the overall economy, and your clients’ industries? For example, how might you benefit from reading industry reports, exploring peer firms' websites, attending conferences, or reading client alerts about recent developments?",
              'How can you engage with and maintain conversations with clients and colleagues about your observations? How can you share insights through your firm’s website, blog posts, LinkedIn, conference panels, and other platforms?',
            ],
          },
        ],
        typical_behaviors: [
          'Understands market trends and implications',
          'Anticipates client needs',
          'Pushes back on client requests when appropriate',
        ],
      },
      {
        name: 'Execution',
        icon_url: '/icons/Execution.svg',
        icon_no_circle_url: '/icons/ExecutionNoCircle.svg',
        hex_color: '#64B0BB',
        definition: 'How you plan for and execute your work.',
        competencies: [
          {
            name: 'Analytical',
            definition:
              'An analytical lawyer effectively synthesizes and integrates information. They know what is critical and what can be discounted. They effectively cut to the heart of a matter, identifying the root cause of a problem. An analytical lawyer considers multiple sources of information and perspectives when arriving at a conclusion.',
            for_reflection: [
              'How do you identify (or help your team identify) the critical issue(s) before tackling a new project? How do you ensure that any connections or patterns between information are considered, and how do you distinguish between what is relevant and what is not?',
              'What is your process for clarifying what, if any, information or resources are unavailable or not taken into account before reaching a final conclusion?',
              'If doing research for an assignment, how do you approach collecting, synthesizing, and incorporating the information you uncover?',
              'What do you do when you get conflicting information? How do you approach reconciling it?',
            ],
          },
          {
            name: 'Decisive',
            definition:
              'A decisive lawyer makes use of all available information, even when information is limited or ambiguous. They can make rapid decisions when needed and consistently demonstrate sound judgment. They anticipate others’ positions and draw on others in the decision-making process.',
            for_reflection: [
              "What do you need to be confident in making a decision? How do you reality-check your decisions? How much consideration do you give to other people's perspectives, and how do you weigh their input versus yours?",
              'To what extent and in what circumstances do you revisit or second-guess decisions? What strategies do you use to ensure that your decisions are final, barring any new, material information? If you tend to second-guess yourself, consider allowing time for your decision to rest—say, 24 hours, if circumstances permit. After that, test it if necessary. The goal is to develop confidence in your original decision, which having some distance may help provide.',
              'What can you do to restrict the amount of time you spend thinking about something? Consider conducting a pain/gain analysis to understand whether additional research or reflection is warranted.',
            ],
          },
          {
            name: 'Planful',
            definition:
              'A planful lawyer understands how to effectively prepare in advance. They are unlikely to begin work without having first considered a timeline and approach. They create contingency plans in case their first plan does not work out.',
            for_reflection: [
              'What techniques do you use to prepare and plan your time? For example, when starting an assignment or project, how beneficial would it be to work backward from your deadline, defining each step and the time required? How else can you make realistic estimates and account for potential delays?',
              'When starting a project, would it help to ask yourself what roadblocks you might encounter and prepare to pivot if necessary? What fallback option(s) can you identify so you can change course as needed?',
              'How can you break projects into smaller and more manageable tasks? Can you create interim deadlines for yourself, with buffers built in before the final deadline?',
              'How do you use a to-do list? How specific do you make it? Can you use it to block time in your calendar to ensure the list is realistically achievable on your intended timeline? What happens when facts change? How do you adapt your plan?',
            ],
          },
        ],
        typical_behaviors: [
          'Identifies what is most important',
          'Makes decisions effectively',
          'Stays organized and prepared, even for the unexpected',
        ],
      },
    ],
  },
  {
    name: 'Harnessing Energy',
    dimensions: [
      {
        name: 'Pursuit of Excellence',
        icon_url: '/icons/PursuitOfExcellence.svg',
        icon_no_circle_url: '/icons/PursuitOfExcellenceNoCircle.svg',
        hex_color: '#33937C',
        definition:
          'How you approach work, meet expectations, and embrace challenges.',
        competencies: [
          {
            name: 'Driven',
            definition:
              'A driven lawyer is relentless and approaches tasks with urgency, energy, and enthusiasm. They do what needs to be done and are willing to work hard. A lawyer with high drive is likely to be engaged deeply in their work and action-oriented.',
            for_reflection: [
              'How do you approach projects that have no clear deadlines? How do you motivate yourself to start and complete those kinds of projects? How does getting a jump start impact you? Does it make you feel more engaged early on? Does it help you become more invested in the outcome? How can you build a habit around this type of behavior?',
              'How do you relate to winning, and how important is it to you to win at work? What does that look like for you? If you are competitive, how does it impact your relationships with others?',
              'Do you prefer familiar tasks you have done before, or do you enjoy new challenges? If you tend to avoid high-challenge tasks, how could you become more comfortable taking them on? For example, when working on a team, consider whether there are tasks outside your defined responsibilities where you could step up.',
              'How do you balance work and personal time? What do you think about when deciding how to spend your time?',
            ],
          },
          {
            name: 'Enterprising',
            definition:
              'An enterprising lawyer sees the opportunity in every situation and is open to new experiences. They skill up rapidly, seizing every chance to engage with any new topics or people that come their way. They understand their strengths and know how to leverage them to add value to projects. At higher levels, an enterprising lawyer has skills that differentiate them from others.',
            for_reflection: [
              'How do you continue to develop new expertise and skills and/or work outside your existing sweet spot? How do you create opportunities to collaborate with new people, whether clients or colleagues?',
              'How open are you to taking on matters and projects that are stretch assignments for you and/or your team, if you have one?',
              'When you consider the areas where you naturally excel, what has enabled you to reach this point? What sets you apart, and how can you create opportunities to showcase your skills and successes?',
              'How can you ensure that you convey your value to others? Where do you think your blind spots are? How can you find and address any blind spots you identify?',
            ],
          },
          {
            name: 'Focused on Quality',
            definition:
              "A lawyer who is focused on quality produces quality work within the appropriate timeframe. They pay attention to details and ensure that mistakes are eliminated, reviewing their own and others' work and adhering to high standards. Focusing on quality requires diligence, conscientiousness, and efficiency.",
            for_reflection: [
              'Before starting a project or assignment, what steps do you take to understand what is expected of you to ensure you deliver it on time and on budget? Needs and expectations can vary—sometimes a quick answer is preferred over a detailed memo of analysis and advice. How can you get clarity around what an acceptable finished work product should look like?',
              'When budgeting time to produce a work product that meets expectations, what do you consider? How effective is your process? What would make it better? If you find yourself deviating from the time allowed, how can you ensure that you take time to consider whether you are doing too much or too little and adjust accordingly?',
              'Whether submitting your own work to a client or colleague or sharing the work of others to whom you delegated, what steps do you take to ensure the work product meets the client’s needs and is accurate and error-free?',
              'What strategies do you rely on to complete your own work or ensure that others complete their tasks efficiently? How do you integrate these strategies into your regular approach?',
            ],
          },
          {
            name: 'Resolute',
            definition:
              'A resolute lawyer is gritty, adept at rebounding from setbacks, and comfortable confronting others when circumstances warrant. They take stands on critical issues and manage conflict directly and effectively. A resolute lawyer is unflappable.',
            for_reflection: [
              'How do you handle disappointments and setbacks? What do you do to overcome them in your work? How open are you to the idea that how you react to a setback matters more than the setback itself?',
              'How willing are you to reframe a mistake or a failure as an opportunity? What is possible as a result?',
              'What do you do when someone challenges you about something you said or did? Would you rather walk away or deal with challenges head-on?',
              'When do you find yourself getting derailed by challenging tasks? What can you focus on first? If you focus on the more straightforward elements, can you circle back to the more complex tasks later with more confidence and a fresh perspective?',
              "How do you manage difficult conversations? Can you look at them as an opportunity to uncover the other person's underlying motivations and clarify assumptions and expectations? Asking open-ended questions at the outset and spending more time listening may make it easier to understand different perspectives and viewpoints.",
            ],
          },
        ],
        typical_behaviors: [
          'Minimizes mistakes',
          'Embraces new opportunities',
          'Takes a stand when necessary',
          'Is not deterred by obstacles',
        ],
      },
      {
        name: 'Dynamic Approach',
        icon_url: '/icons/DynamicApproach.svg',
        icon_no_circle_url: '/icons/DynamicApproachNoCircle.svg',
        hex_color: '#E87722',
        definition:
          'How you adapt to changing circumstances and differing contexts.',
        competencies: [
          {
            name: 'Adaptable',
            definition:
              'An adaptable lawyer adjusts to different situations. They are effective in navigating ambiguous, uncertain, or changing environments and take action with limited information. An adaptable lawyer thinks on their feet and puts structure in place when there is none. They can lead and follow as needs permit and adapt their leadership style to individual situations and subordinates.',
            for_reflection: [
              'How well do you understand the different leadership, working, and communication styles of your colleagues? How do others approach situations differently from you? What and how can you incorporate other styles in addressing the needs and preferences of your colleagues?',
              'When you think through different ways to approach a problem, what strategies have you not tried yet? How could using a different approach change outcomes?',
              'When you find yourself in a situation with ambiguous or unclear information, what steps do you take to identify what information is missing and determine how you will address any gaps?',
              'When you last encountered an unexpected situation, what was most helpful in enabling you to work through it? How, if at all, would you change your behavior in retrospect?',
            ],
          },
          {
            name: 'Dynamic Communicator',
            definition:
              'A dynamic communicator can convey not only what they have done, but what needs to be done, timelines, and next steps. They are effective in communicating orally and in writing, conveying information concisely and with clarity, and tailoring their message to their audience.',
            for_reflection: [
              "What steps do you take to understand your client's (or colleague's) priorities and needs so that you can clearly and concisely communicate what is most relevant and important?",
              'How do you prefer to communicate: in person, by video, by phone, by email, or by text? Your preferences may not work the same way for others as they do for you.  What can you do to ensure that you are communicating in a way that will have the impact you want? How can you adapt your communication style for different audiences, contexts, and purposes?',
              'When working with new people, how do you identify the amount of detail, frequency, and communication channel they prefer (either by asking or observing typical patterns) and adapt your style accordingly?',
            ],
          },
          {
            name: 'Resourceful',
            definition:
              'A resourceful lawyer studies their environment to understand how best to meet the needs of that environment. They are discerning about who they go to for help and are able to teach themselves and figure things out on their own.   ',
            for_reflection: [
              'How well do you know those people whose opinions and expertise you regularly require? If you called or emailed, how quickly would they respond?',
              'What can you do to expand your relationships and understand the capabilities and interests of your colleagues? How can you build rapport with them so that you both feel comfortable reaching out as and when needed and so that they will help you if you ask?',
              'What different resources (internal and external) does your firm make available and offer to its people that you can avail yourself of if needed? What resource(s) could you try to see how it might help you to be more efficient or effective?',
              'What technology is available to you that would support you in your work? How do you ensure that you embrace new technologies your firm makes available? What technologies are others in your group/industry using that you have not tried yet?',
            ],
          },
        ],
        typical_behaviors: [
          'Changes behaviors based on the situation',
          'Tries things first before turning to others',
          'Communicates effectively with different audiences',
        ],
      },
    ],
  },
];
